import {getRequestForBff, postRequestForBff} from '../axios/axiosVerbs';
import {LegalLanguageText} from '../models/LegalLanguageText';
import {CustomerLegalLanguageResponse} from '../models/CustomerLegalLanguageResponse';
import {DebitCardConsentStatusResponse} from '../models/DebitCardConsentStatusResponse';

export const getConsentByExternalId = () =>
  getRequestForBff<CustomerLegalLanguageResponse[]>('v1/consent/consent-by-customer-id', true);

export const getConsentTexts = (optType: string) =>
  getRequestForBff<LegalLanguageText[]>('v1/consent/active-consent-text-by-type?optType=' + optType, true);

export const getDebitCardConsentStatus = () =>
  getRequestForBff<DebitCardConsentStatusResponse>('v1/contract/contract-by-customer-id?sourceSystem=WEB', true);

export const updateDebitCardAcceptance = (status: 'IGNORED' | 'ACCEPTED', legalLanguageCode: string) =>
  postRequestForBff<DebitCardConsentStatusResponse>('v1/contract/create-customer-contract-mapping', {
    mobileStatus: null,
    webStatus: status,
    legalLanguageCode
  }, true);
