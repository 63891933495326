// src/hooks/useDebitCardConsent.ts
import {useEffect, useState} from 'react';
import {getDebitCardConsentStatus} from '../../services/consentService';
import {DebitCardConsentStatusResponse} from "../../models/DebitCardConsentStatusResponse";
import {AxiosResponse} from "axios";

export function useDebitCardConsent(context: 'DASHBOARD' | 'ADD_PAYMENT_METHOD') {
  const [showModal, setShowModal] = useState(false);
  const [consentText, setConsentText] = useState('');
  const [legalLanguageCode, setLegalLanguageCode] = useState('');

  async function fetchConsentStatus() {
    try {
      const debitCardResponse: AxiosResponse<DebitCardConsentStatusResponse[]> = await getDebitCardConsentStatus();
      if (debitCardResponse.status !== 200) {
        console.error('Error fetching consent status:', debitCardResponse);
        return;
      }

      if (context === 'DASHBOARD' && debitCardResponse?.data[0]?.status !== 'PENDING') {
        return;
      }

      if (context === 'ADD_PAYMENT_METHOD' && debitCardResponse?.data[0]?.status === 'ACCEPTED') {
        return;
      }

      setConsentText(debitCardResponse?.data[0]?.contractText || '');
      setLegalLanguageCode(debitCardResponse?.data[0]?.legalLanguageCode || '');
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching consent status:', error);
    }
  }

  useEffect(() => {
    fetchConsentStatus().then();
  }, []);

  return {showModal, consentText, legalLanguageCode};
}
