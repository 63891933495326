import { RefObject, useEffect } from 'react';

export const useFocusEffect = (ref: RefObject<HTMLElement>, condition: boolean) => {
  useEffect(() => {
    if (condition && ref.current) {
      ref.current.focus();

      const announcement = document.createElement('div');
      announcement.setAttribute('aria-live', 'polite');
      announcement.textContent = 'Accept button is now enabled';
      document.body.appendChild(announcement);

      setTimeout(() => document.body.removeChild(announcement), 5000);
    }
  }, [condition, ref]);
};
