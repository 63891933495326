import axios from 'axios';

export const axiosInstanceForBff = axios.create({});

axiosInstanceForBff.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Handle 401 error here
      if (`${window.__RUNTIME_CONFIG__.REACT_APP_FLOW_TYPE}` === 'NOLOGIN') {
        window.location.replace('/login-email');
      } else {
        window.location.replace('/login-uber');
      }
    }
    return Promise.reject(error);
  }
);
