import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import ContactUsModal from '../../components/Modal/ContactUsModal/ContactUsModal';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';
import classes from './ExperianRejection.module.scss';
import {trackExperianRejectionPageLoad} from '../../services/analyticsService';

const ExperianRejection = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    trackExperianRejectionPageLoad();
  }, []);

  return (
    <>
      <div className="l-height-bg">
        <NavWebHeader title="Checkout" shouldNav={false} />
        <div className="l-border-radius-bg">
          <div className="l-center">
            <section className={classes.content}>
              <div className={classes['content-size']}>
                <img className={classes.image} src={'/assets/images/Rejection.svg'} alt="Ryze Rejection Logo" />
                <h5 className={classes.header}>We’re unable to approve your application.</h5>
                <div className={classes.text}>
                  <p>
                    We appreciate your interest in the Ford Drive Rideshare Program. Unfortunately, we’re not able to
                    move forward with your application at this time.
                  </p>
                  <p>
                    If you would like to review a copy of your credit profile, you may contact Experian directly at:
                  </p>
                  <p className={classes.bolded}>
                    Experian MCE (My Customer Experience)
                    <br />
                    PO Box 74 Allen, TX 75013
                    <br />
                    Phone (855) 819-7973
                    <br />
                    Fax (972) 390-4987
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
      <ContactUsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default ExperianRejection;
