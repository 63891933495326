import React, { useEffect, useRef } from 'react';
import Dialog, { DialogClose, DialogContent } from '../../Dialog';
import styles from './DebitCardContractModal.module.scss';
import Spacer from '../../Spacer';
import MarkdownRenderer from '../../MarkdownRenderer';
import { trackDebitCardContractModalShown } from '../../../services/analyticsService';
import { ScreenName } from './constants';
import { useScrollDetection } from '../../../hooks/useScrollDetection';
import { useFocusEffect } from '../../../hooks/useFocusEffect';

interface DebitCardContractModalProps {
  consentText: string;
  screenName: ScreenName;
  onAccept: () => void;
  onIgnore: () => void;
}

const DebitCardContractModal: React.FC<DebitCardContractModalProps> = ({
  consentText,
  screenName,
  onAccept,
  onIgnore,
}) => {
  const contractTextRef = useRef<HTMLDivElement>(null);
  const acceptButtonRef = useRef<HTMLButtonElement>(null);

  const isEndReached = useScrollDetection(contractTextRef);
  useFocusEffect(acceptButtonRef, isEndReached);

  useEffect(() => {
    trackDebitCardContractModalShown({ screen_name: screenName });
  }, [screenName]);

  return (
    <Dialog defaultOpen={true}>
      <DialogContent title="We've Updated our Legal Terms">
        <Spacer height={8} />
        <p id="instructions" className={styles.instructions}>
          Please review this document to continue.
        </p>
        <Spacer height={16} />
        <div
          className={styles.contractText}
          tabIndex={0}
          ref={contractTextRef}
          aria-labelledby="instructions"
          data-testid="contractText"
        >
          <MarkdownRenderer>{consentText}</MarkdownRenderer>
        </div>
        <Spacer height={32} />
        <DialogClose asChild={true}>
          <div className="l-flex l-gap">
            <button className="button--ghost l-flex-1" onClick={onIgnore} data-testid="debitCardModalIgnoreButton">
              Ignore
            </button>
            <button
              className="button--primary--next l-flex-1"
              disabled={!isEndReached}
              ref={acceptButtonRef}
              aria-disabled={!isEndReached}
              onClick={onAccept}
              data-testid="debitCardModalAccepButton"
            >
              Accept
            </button>
          </div>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default DebitCardContractModal;
