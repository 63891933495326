import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './MarkdownRenderer.module.scss';

function MarkdownRenderer({ children }) {
  return (
    <div className={styles.markdownContent}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </div>
  );
}

export default MarkdownRenderer;
