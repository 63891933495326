import React, { useContext } from 'react';
import { ExpandContext } from '../Dropdown';
import classes from './ScheduleYourPickup.module.scss';

const ScheduleYourPickupBody = () => {
  const setIsOpen = useContext(ExpandContext);
  return (
    <div className={classes.wrapper}>
      <p className={classes.text}>Our care team will contact you to</p>
      <p className={classes.text}>schedule your vehicle pickup. Or call us</p>
      <p className={classes.text}>now to get started</p>
      <p className={`${classes.content} ${classes.text}`}>General Support: 9am - 5pm PT, M-F</p>
      <button
        className="button-pickup"
        onClick={() => {
          setIsOpen(false);
          window.location.href = 'tel:800-805-5462';
        }}
      >
        Call Customer Care
      </button>
    </div>
  );
};

export default ScheduleYourPickupBody;
