import React, { FC, useEffect } from 'react';
import classes from './PersonalInfo.module.scss';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../../../models/UserProfile';
import { RedirectTo } from '../../../UpdateAddressForm/UpdateAddressForm';
import { reservationStorageAtom } from '../../../Dashboard/Dashboard';
import { trackProfileView } from '../../../../services/analyticsService';
import CreditCardDetails from '../../../../components/CreditCardDetails/CreditCardDetails';
import { ReservationStatus } from '../../../../models/ReservationStatus';
import LeasePackage from '../../../../components/widgets/LeasePackage/LeasePackage';
import { formatDate, leaseDateFormat } from '../../../../utils/dateUtil';
import PersonalInfoDeleteModal from '../../../../components/PersonalInfoDeleteModal/PersonalInfoDeleteModal';
import { getAllCards } from '../../../../services/customerService';
import { HTTP_STATUS } from '../../../../models/HttpStatus';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import Spacer from '../../../../components/Spacer';
import DeleteUserDataModal from '../../../../components/Modal/DeleteUserDataModal';
import { getDataDeletionStatus } from '../../../../services/deleteDataOrchestratorService';
import { showToastStateless } from '../../../../components/CustomToast/Toast';
import { convertReservationToSubscriptionStatus } from '../../../../utils/convertSubscriptionStatus';

const PersonalInfo: FC<{ userProfile: UserProfile }> = ({ userProfile }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [hasOneOrMoreCards, setHasOneOrMoreCards] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isDataDeletionLoading, setIsDataDeletionLoading] = React.useState(false);

  const [reservation] = useAtom(reservationStorageAtom);
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const currentDate = new Date();

  useEffect(() => {
    trackProfileView();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllCards();
        if (response.status !== HTTP_STATUS.OK) {
          console.error('Error fetching cards', response);
        }
        response.data && isMounted() && setHasOneOrMoreCards(response.data.length > 0);
      } catch (error) {
        console.error('Failed to fetch all cards:', error);
      }
    })();
  }, [navigate, isMounted]);

  const handleEditProfile = () => {
    navigate('/update-address/' + RedirectTo.PROFILE);
  };

  const shouldRenderLeasePackage = () => {
    if (JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED)) {
      return (
        reservation &&
        [
          ReservationStatus.WAITLISTED,
          ReservationStatus.PENDING,
          ReservationStatus.ACTIVE,
          ReservationStatus.PENDING_RETURN,
        ].includes(reservation.status)
      );
    } else {
      return reservation && [ReservationStatus.ACTIVE, ReservationStatus.PENDING_RETURN].includes(reservation.status);
    }
  };

  const shouldDisablePackageSwitchForRetail = () => {
    return JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_RETAIL_FLOW_FLAG) && reservation?.workflowId === 'RETAIL';
  };

  async function handleDeleteModalClick() {
    setIsDataDeletionLoading(true);
    const response = await getDataDeletionStatus();

    if (response?.status === 404 || response?.data?.status === 'CANCELLED') {
      setShowDeleteModal(true);
    }

    if (response?.status !== 200 && response?.status !== 404 && response?.status >= 400) {
      showToastStateless(
        '',
        'Something went wrong. Try again later.',
        `${process.env.PUBLIC_URL}/assets/images/warning.svg`,
        '#D31700',
        3000
      );
    }

    if (response?.data?.status === 'IN_PROGRESS') {
      showToastStateless(
        '',
        'Your deletion request is already in progress.',
        `${process.env.PUBLIC_URL}/assets/images/Checkmark.svg`,
        '#047B41',
        3000
      );
    }

    if (response?.data?.status === 'PAUSED') {
      showToastStateless(
        '',
        'The request to delete your data has been paused. Please call customer care for further action.',
        `${process.env.PUBLIC_URL}/assets/images/Alert.svg`,
        '#FA9600',
        3000
      );
    }

    setIsDataDeletionLoading(false);
  }

  return (
    <div className={classes.personal_info_container}>
      <div className={classes.personal_info_header_area}>
        <div className={classes.personal_info_header_title}>Personal Profile</div>
        <a className={classes.personal_info_header_edit} onClick={handleEditProfile} data-testid="edit-button">
          Edit
        </a>
      </div>
      <div className={classes.personal_info_body}>
        <div className={classes.data}>
          <div className={classes.label}>Full Name</div>
          <div className={classes.text} data-testid="user-profile-name">
            {userProfile?.name?.displayName}
          </div>
        </div>

        <div className={classes.data}>
          <div className={classes.label}>Email</div>
          <div className={classes.text} data-testid="user-profile-email">
            {userProfile?.email}
          </div>
        </div>

        <div className={classes.data}>
          <div className={classes.label}>Phone Number</div>
          <div className={classes.text} data-testid="user-profile-phoneNumber">
            {userProfile?.phoneNumber}
          </div>
        </div>

        <div className={classes.data}>
          <div className={classes.label}>Date of Birth</div>
          <div className={classes.text} data-testid="user-profile-dob">
            {userProfile?.dateOfBirth}
          </div>
        </div>
      </div>
      {hasOneOrMoreCards && (
        <div className={classes.credit_card}>
          <CreditCardDetails />
        </div>
      )}
      {shouldRenderLeasePackage() && <LeasePackage disableSwitch={shouldDisablePackageSwitchForRetail()} />}
      {reservation && reservation.status === ReservationStatus.ACTIVE && (
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          {reservation.subscriptionTermDate && new Date(reservation.subscriptionTermDate) > currentDate && (
            <p>Minimum lease term: {formatDate(reservation.subscriptionTermDate, leaseDateFormat)}</p>
          )}
        </div>
      )}
      <Spacer height={32} />
      <button onClick={() => setIsModalOpen(true)} className="button--ghost-info">
        Manage Subscription
      </button>
      <PersonalInfoDeleteModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Spacer height={16} />
      {JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_DELETE_DATA_ENABLED) && (
        <button className="button--ghost-danger" onClick={handleDeleteModalClick}>
          {isDataDeletionLoading ?
            <div className="l-flex-center" style={{ height: '22px' }}>
              <img className="spinner button-spinner" src={'/assets/images/spinner-red.svg'} alt="processing" />
            </div>
          : 'Delete User Data'}
        </button>
      )}
      {showDeleteModal && (
        <DeleteUserDataModal
          status={convertReservationToSubscriptionStatus(reservation.status)}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
        />
      )}
    </div>
  );
};

export default PersonalInfo;
