import { RefObject, useEffect, useState } from 'react';

export const useScrollDetection = (ref: RefObject<HTMLElement>) => {
  const [isEndReached, setIsEndReached] = useState(false);

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = currentRef;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setIsEndReached(true);
      }
    };

    if (currentRef.scrollHeight <= currentRef.clientHeight) {
      setIsEndReached(true);
    } else {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      currentRef.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);

  return isEndReached;
};
