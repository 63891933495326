import { toast } from 'react-hot-toast';

type ToasterContentProps = {
  title: string;
  description: string;
  borderColor: string;
  image: string;
  toastId: string;
};

export function Toast({ title, description, borderColor, image, toastId }: ToasterContentProps) {
  function handleDismiss() {
    toast.remove(toastId);
  }

  return (
    <div className="toaster">
      <div className="toaster__content" style={{ borderLeft: `2px solid ${borderColor}` }}>
        <img src={image} alt="toaster image type" />
        <div className="toaster__text">
          <div className="toaster__title">{title}</div>
          <div className="toaster__description">{description}</div>
        </div>
        <img
          data-testid="dismiss"
          onClick={handleDismiss}
          src={`${process.env.PUBLIC_URL}/assets/images/x.svg`}
          style={{ cursor: 'pointer' }}
          alt="close icon"
        />
      </div>
    </div>
  );
}

export function showToastStateless(
  title: string,
  description: string,
  image: string,
  borderColor: string,
  duration = Infinity
) {
  toast.custom(
    (t) => <Toast title={title} description={description} image={image} borderColor={borderColor} toastId={t.id} />,
    {
      duration,
      style: {
        marginTop: '20px',
      },
      id: 'copy',
    }
  );
}
