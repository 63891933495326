import { axiosInstanceForBff } from './axios';
import axiosRetry from 'axios-retry';
import { RetryConfig } from '../models/RetryConfig';
import { defaultRetryConfigGet, defaultRetryConfigPost } from './axiosRetryConfigurations';
import axios from 'axios'; /*
 * Below functions will be used for web-bff calls
 * without any Auth Header
 * and with `withCredentials: true`
 *
 */

/*
 * Below functions will be used for web-bff calls
 * without any Auth Header
 * and with `withCredentials: true`
 *
 */
const getRetryConfigForBff = (retryConfig: RetryConfig, shouldRetry?: boolean) => {
  axiosRetry(axiosInstanceForBff, { retries: 0 });

  const emptyConfig = {}; //empty config will not override retries: 0 config if previously set
  return shouldRetry === true ? retryConfig : emptyConfig;
};

export const getRequestForBff = async <T>(
  URL: string,
  shouldRetry?: boolean,
  retryConfig: RetryConfig = defaultRetryConfigGet
) => {
  const customConfig = getRetryConfigForBff(retryConfig, shouldRetry);
  try {
    return await axiosInstanceForBff.get<T>(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST + URL}`, {
      'axios-retry': customConfig,
      withCredentials: true,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return err.response;
    }
    return err;
  }
};

export const downloadRequestForBff = async <T>(
  _URL: string,
  shouldRetry?: boolean,
  retryConfig: RetryConfig = defaultRetryConfigGet
) => {
  const customConfig = getRetryConfigForBff(retryConfig, shouldRetry);
  try {
    return await axiosInstanceForBff.get<T>(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST + _URL}`, {
      'axios-retry': customConfig,
      withCredentials: true,
      responseType: 'blob',
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return err.response;
    }
    return err;
  }
};

export const postRequestForBff = async <T>(
  URL: string,
  payload: any,
  shouldRetry?: boolean,
  retryConfig: RetryConfig = defaultRetryConfigPost
) => {
  const customConfig = getRetryConfigForBff(retryConfig, shouldRetry);
  try {
    return await axiosInstanceForBff.post<T>(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST + URL}`, payload, {
      'axios-retry': customConfig,
      withCredentials: true,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response;
    }
    throw err;
  }
};
export const patchRequestForBff = async <T>(
  URL: string,
  payload: any,
  shouldRetry?: boolean,
  retryConfig: RetryConfig = defaultRetryConfigPost
) => {
  const customConfig = getRetryConfigForBff(retryConfig, shouldRetry);
  try {
    return await axiosInstanceForBff.patch<T>(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST + URL}`, payload, {
      'axios-retry': customConfig,
      withCredentials: true,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response;
    }
    throw err;
  }
};
export const deleteRequestForBff = async <T>(
  URL: string,
  shouldRetry?: boolean,
  retryConfig: RetryConfig = defaultRetryConfigPost
) => {
  const customConfig = getRetryConfigForBff(retryConfig, shouldRetry);
  try {
    return await axiosInstanceForBff.delete<T>(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST + URL}`, {
      'axios-retry': customConfig,
      withCredentials: true,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response;
    }
    throw err;
  }
};
export const putRequestForBff = async <T>(
  URL: string,
  payload: any,
  shouldRetry?: boolean,
  retryConfig: RetryConfig = defaultRetryConfigPost
) => {
  const customConfig = getRetryConfigForBff(retryConfig, shouldRetry);
  try {
    return await axiosInstanceForBff.put<T>(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST + URL}`, payload, {
      'axios-retry': customConfig,
      withCredentials: true,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      return err.response;
    }
    return err;
  }
};
