import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { createPaymentIntent } from '../../services/stripeService';
import { useNavigate } from 'react-router-dom';
import { loadStripe, PaymentMethod, StripeElementsOptions } from '@stripe/stripe-js';
import { APPEARANCE } from './StripePaymentForm.const';
import { ErrorType } from '../../pages/ErrorViewer/ErrorViewer';
import { SuccessType } from '../../pages/Success';
import { useDebitCardConsent } from '../../pages/Dashboard/useDebitCardConsent';
import DebitCardContractModal from '../Modal/DebitCardContractModal';
import ReactDOM from 'react-dom';
import { updateDebitCardAcceptance } from '../../services/consentService';
import { trackDebitCardContractModalClosed } from '../../services/analyticsService';

export const stripePromise = loadStripe(`${window.__RUNTIME_CONFIG__.REACT_APP_STRIPE_PK}`);

interface StripeElementProps {
  clientSecret?: string;
  paymentMethod?: (id: string | PaymentMethod) => void;
  isNameEmpty?: boolean;
}

const StripePaymentForm = ({ paymentMethod, isNameEmpty }: StripeElementProps) => {
  const [options, setOptions] = useState<StripeElementsOptions>(null);
  const navigate = useNavigate();

  useEffect(() => {
    createPaymentIntent()
      .then((resp) => {
        setOptions({
          clientSecret: resp.data.clientSecret,
          appearance: APPEARANCE,
        });
      })
      .catch(() => navigate(`/error/${ErrorType.WAITLIST}`));
  }, []);

  return (
    <Elements stripe={stripePromise} options={null}>
      {options && (
        <StripeElement clientSecret={options.clientSecret} paymentMethod={paymentMethod} isNameEmpty={isNameEmpty} />
      )}
    </Elements>
  );
};

const StripeElement = ({ clientSecret, paymentMethod, isNameEmpty }: StripeElementProps) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const { showModal, consentText, legalLanguageCode } = useDebitCardConsent('ADD_PAYMENT_METHOD');

  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();

  async function handleDebitCard() {
    return new Promise((resolve, reject) => {
      const modal = document.createElement('div');
      document.body.appendChild(modal);

      async function handleAccept() {
        trackDebitCardContractModalClosed({ action: 'ACCEPTED' });
        try {
          await updateDebitCardAcceptance('ACCEPTED', legalLanguageCode);
          resolve(true);
          document.body.removeChild(modal);
        } catch {
          reject(new Error('Could not update debit card acceptance status.'));
          document.body.removeChild(modal);
        }
      }

      async function handleIgnore() {
        trackDebitCardContractModalClosed({ action: 'IGNORED' });
        await updateDebitCardAcceptance('IGNORED', legalLanguageCode);
        reject(new Error('You need to accept the contract text in order to add a debit card.'));
        document.body.removeChild(modal);
      }

      ReactDOM.render(
        <DebitCardContractModal
          consentText={consentText}
          screenName={'ADD_PAYMENT_METHOD'}
          onAccept={handleAccept}
          onIgnore={handleIgnore}
        />,
        modal
      );
    });
  }

  const checkCardType = async (cardType: string) => {
    if (cardType === 'debit' && showModal) {
      try {
        await handleDebitCard();
      } catch (err) {
        setError(err.message);
        setProcessing(false);
        return err.message;
      }
    }

    const errorMsg: string =
      ['prepaid', 'unknown'].includes(cardType) ?
        'Invalid card number. Please enter a valid payment card number. Prepaid cards are not accepted.'
      : '';
    return errorMsg;
  };

  const handleSubmit = async () => {
    setProcessing(true);
    setError('');

    const card = elements.getElement('card');
    const createdToken = await stripe.createToken(card);

    // set error messages for create token call
    const tokenError = createdToken?.error?.message;
    const cardTypeError = await checkCardType(createdToken?.token?.card?.funding);
    const errorOccurred = tokenError || cardTypeError;
    setError(errorOccurred);

    // confirm card setup
    if (!errorOccurred) {
      const setupIntentResponse = await stripe.confirmCardSetup(clientSecret, { payment_method: { card } });

      if (setupIntentResponse.error) {
        setError(setupIntentResponse.error.message);
      } else {
        paymentMethod(setupIntentResponse.setupIntent.payment_method);
        card.unmount();
        navigate(`/success/${SuccessType.ADD}`);
      }
    }

    setProcessing(false);
  };

  return (
    <div className="l-abs-center">
      {elements && (
        <div className="container" style={{ maxWidth: '327px' }}>
          <CardElement />
          {error && <p className="error">Card Error: {error}</p>}
        </div>
      )}
      <button className="button--primary l-margin-top l-abs-center" onClick={handleSubmit} disabled={isNameEmpty}>
        {processing ?
          <img className="spinner button-spinner" src={'/assets/images/spinner-white.svg'} alt="processing" />
        : 'Add Payment Method'}
      </button>
    </div>
  );
};

export default StripePaymentForm;
